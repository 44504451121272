.hdb-change-password {
  flex: 1;
  height: 100%;

  .hdb-blue-gradient-container {
    position: relative;
    height: 100%;

    &:after {
      display: none;
    }
  }
  
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: var(--hdb-space-3x) 0 var(--hdb-space-5x) 0;
    background: url('../../../../assets/img/hdb-circles-bg-2.png') center top no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 2;
  }

  &__title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:after {
      display: block;
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 2px;
      background: var(--hdb-blue);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: var(--hdb-space-3x);
  }

  &__content {
    padding: var(--hdb-space-3x) 0;
  }

  &__password-validations {
    
    & > p {
      margin: var(--hdb-space-2x) 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: var(--hdb-space-3x);

    button {

      & + button {
        margin-left: var(--hdb-space-2x);
      }
    }
  }
  
  .hdb-input-group__prepend,
  .hdb-input-group__append {
    z-index: 3;
  }

  .hdb-input-group__prepend {

    i, svg {
      font-size: 1.6rem;
    }
  }

  .hdb-input-group__append {

    i, svg {
      font-size: 1.8rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .hdb-change-password {

    &__actions {
      flex-direction: column;

      button {
        width: 100%;

        & + button {
          margin-left: 0;
          margin-top: var(--hdb-space-2x);
        }
      }
    }

    &__right-actions,
    &__left-actions {
      width: 100%;
    }

    &__right-actions {
      width: 100%;
      margin-top: var(--hdb-space-2x);
    }
  }
}
