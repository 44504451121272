.hdb-update-account {
  flex: 1;
  height: 100%;

  .hdb-blue-gradient-container {
    position: relative;
    height: 100%;
    
    &:after {
      display: none;
    }
  }
  
  &__circles {
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 2;
  }

  &__title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:after {
      display: block;
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 2px;
      background: var(--hdb-blue);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }
  
  &__content {
    padding: var(--hdb-space-4x) 0;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--hdb-space-2x) 1.5%;
    background: transparent;
    border-top: solid 1px #CCCCCC;
    
    button {
      
      & + button {
        margin-left: var(--hdb-space-2x);
      }
    }
  }
  
  &__user-document {
    margin-top: 20px;
  }
}

@media screen and (max-width: 500px) {
  .hdb-update-account {

    &__actions {
      flex-direction: column;

      button {
        width: 100%;

        & + button {
          margin-left: 0;
          margin-top: var(--hdb-space-2x);
        }
      }
    }

    &__right-actions,
    &__left-actions {
      width: 100%;
    }

    &__right-actions {
      width: 100%;
      margin-top: var(--hdb-space-2x);
    }
  }
}

