.hdb-list {
    padding: 0;
    margin: 0;
  
  &__unstyled {
    list-style: none;
  }

  li {
    padding: var(--hdb-half-space) 0;
  }
}
