:root {
  --hdb-white: #FFFFFF;
  
  --hdb-black: #000000;
  --hdb-black-alpha-10: rgba(0, 0, 0, 0.10);
  --hdb-black-alpha-25: rgba(0, 0, 0, 0.25);
  --hdb-black-alpha-50: rgba(0, 0, 0, 0.5);

  --hdb-blue: #1B1464;
  --hdb-blue-90: #332C73;
  --hdb-blue-70: #605B92;
  --hdb-blue-50: #8E8AB1;
  --hdb-blue-30: #BBB9D0;
  
  --hdb-secundary-blue: #0061AD;
  --hdb-secundary-blue-90: #006EB6;
  --hdb-secundary-blue-70: #5389C8;
  --hdb-secundary-blue-50: #8CA9D8;
  --hdb-secundary-blue-30: #BCCAE9;

  --hdb-light-blue: #1A55DD;
  --hdb-secundary-light-blue: #4285F4;
  
  --hdb-dark-blue: #0F172A;
  
  --hdb-alternativa-blue: #1351B4;
  
  --hdb-green: #00D600;
  --hdb-green-90: #1ADA1A;
  --hdb-green-70: #4DE24D;
  --hdb-green-50: #80EB80;
  --hdb-green-30: #B3F3B3;
  
  --hdb-secondary-green: #268744;
  
  --hdb-purple: #3C0064;
  --hdb-purple-90: #501A74;
  --hdb-purple-70: #774D93;
  --hdb-purple-50: #9E80B1;
  --hdb-purple-30: #C5B3D1;
  
  --hdb-magenta: #BF25D2;
  
  --hdb-lilac: #4A2BE1;
  --hdb-lilac-90: #5C40E4;
  --hdb-lilac-70: #806BEA;
  --hdb-lilac-50: #A495F0;
  --hdb-lilac-30: #C9C0F6;

  --dark-lilac: #140467;

  --hdb-orange: #FF872D;
  --hdb-orange-90: #FF9342;
  --hdb-orange-70: #FFAB6C;
  --hdb-orange-50: #FFC396;
  --hdb-orange-30: #FFDBC0;

  --hdb-red: #FF0014;
  --hdb-red-90: #FF1A2C;
  --hdb-red-70: #FF4D5B;
  --hdb-red-50: #FF8089;
  --hdb-red-30: #FFB3B9;

  --hdb-gray: #333333;
  --hdb-gray-90: #454444;
  --hdb-gray-70: #686666;
  --hdb-gray-50: #898787;
  --hdb-gray-30: #ACA9A9;
  --hdb-gray-20: #CCCCCC;
  --hdb-gray-10: #f8f8f8;
  
  --hdb-secondary-gray: #888888;
  
  --hdb-tertiary-gray: #d5d4d4;
  
  --hdb-gray-alpha: rgba(51, 51, 51, .75);
  
  --hdb-yellow: #FFCD07;

  --hdb-error: var(--hdb-red-90);
  --hdb-warning: var(--hdb-yellow);
  --hdb-sucess: var(--hdb-secondary-green);
  --hdb-info: var(--hdb-secundary-blue);

  --hdb-text-color: var(--hdb-dark-blue);
}
