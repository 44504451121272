.hdb-header {
  position: fixed;
  height: var(--hdb-header-height);
  width: 100%;
  margin: 0;
  padding: 0;
  background: var(--hdb-white);
  -webkit-box-shadow: 0 4px 25px 0 var(--hdb-black-alpha-25);
  -moz-box-shadow: 0 4px 25px 0 var(--hdb-black-alpha-25);
  box-shadow: 0 4px 25px 0 var(--hdb-black-alpha-25);
  z-index: 9;
  
  .container {
    height: 100%;
  }
  
  ul {
    list-style: none !important;
  }

  a {
    text-decoration: none !important;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  &__logo {
    flex: 1;
    
    img {
      height: 50px;
    }
    
    & + .hdb-header__menu {
      margin-left: var(--hdb-space-10x);
    }
  }
  
  &__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 3;
    
    & + .hdb-header__accessibility-controls {
      margin-left: var(--hdb-space-6x);
    }
  }
  
  &__link {
    position: relative;

    a {
      position: relative;
      color: var(--hdb-blue);
      font-weight: 600;
    }

    & + li {
      margin-left: var(--hdb-space-4x);
    }
    
    &--active {
      
      a {
        
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: var(--hdb-blue);
          bottom: -6px;
          left: 0;
        }
      }
    }
  }
  
  &__accessibility-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: var(--hdb-space-6x);
    
    li {
      
      a {
        
        i {

          &.hdb-icon--a-plus {
            font-size: 1.6rem;
          }

          &.hdb-icon--a-minus {
            font-size: 1.2rem;
          }
        }
      }

      & + li {
        margin-left: calc(var(--hdb-default-space) + 2px);
      }
    }
  }
  
  &__auth-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    li {

      & + li {
        margin-left: var(--hdb-default-space);
      }
    }

    & + .hdb-dropdown-menu {
      margin-left: var(--hdb-space-6x);
    }
  }
}

@media (max-width: 330px) {

  .hdb-header {

    &__logo {

      img {
        height: 35px !important;
      }
    }
  }
}

@media (min-width: 331px) and (max-width: 450px) {

  .hdb-header {

    &__logo {

      img {
        height: 45px !important;
      }
    }
  }
}

@media (max-width: 991px) {
  
  .hdb-header {
    padding: var(--hdb-space-2x) var(--hdb-default-space);
    
    .container {
      padding: 0 !important;
    }

    &__menu {
      display: none;
      flex-direction: row;
      flex-wrap: wrap;
      margin: var(--hdb-default-space) !important;

      & + .hdb-header__accessibility-controls {
        margin-left: var(--hdb-space-3x);
      }
    }
    
    &__logo {

      & + .hdb-header__menu {
        margin-left: var(--hdb-space-3x);
      }
    }
    
    &__link {
      width: 50%;
      padding: var(--hdb-default-space) 0;
      text-align: center;
      
      & + li {
        margin-left: 0;
      }
    }

    &__accessibility-controls {
      margin: 0 !important;
    }
    
    &__auth-buttons {
      display: none;

      & + .hdb-dropdown-menu {
        margin-left: var(--hdb-space-3x);
      }
    }
  }
}

@media (max-width: 1200px) {

  .hdb-header {

    &__menu {

      & + .hdb-header__accessibility-controls {
        margin-left: var(--hdb-space-3x);
      }
    }

    &__logo {

      & + .hdb-header__menu {
        margin-left: var(--hdb-space-3x);
      }
    }
    
    &__link {
      font-size: 13px;
      
      & + li {
        margin-left: var(--hdb-space-3x);
      }
    }

    &__accessibility-controls {
      margin-right: var(--hdb-space-3x);
    }
    
    button {
      font-size: 14px;
    }
  }
}
