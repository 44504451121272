.hdb-towards-the-future {
  display: flex;
  flex-direction: column;
  padding-top: var(--hdb-space-10x);
  padding-bottom: var(--hdb-space-20x);

  &__card {
    position: relative;
    width: 82%;
    margin: 0 auto;

    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      right: -10px;
      bottom: -10px;
      background: var(--hdb-lilac);
      border-radius: 10px;
      z-index: 1;
    }
  }

  &__text {
    position: relative;
    background: var(--hdb-white);
    border-radius: 10px;
    border: solid 2px var(--hdb-lilac);
    color: var(--hdb-blue);
    padding: var(--hdb-space-5x) var(--hdb-space-3x);
    text-align: center;
    z-index: 2;

    strong {
      color: var(--hdb-blue);
      font-size: 3.6rem;

      & + p {
        margin-top: var(--hdb-space-2x);
      }
    }

    p {
      color: var(--hdb-blue);
      font-size: 2.4rem;
      line-height: 38.4px;
    }
  }

  &__title {
    color: var(--hdb-blue);
    font-size: 4rem;
    font-weight: 700;

    & + .hdb-towards-the-future__description {
      margin-top: var(--hdb-space-2x);
    }
  }

  &__description {
    color: var(--hdb-text-color);
    font-size: 1.6rem;
    line-height: 30px;
    white-space: pre-wrap;
  }
}

@media (max-width: 575px) {
  .hdb-towards-the-future {

    &__title {
      font-size: 3.2rem;
    }
  }
}