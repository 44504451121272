.hdb-footer {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  font-size: var(--hdb-small-font-size);
  margin: 0;
  padding: 0;
  width: 100%;

  ul {
    list-style: none !important;
  }

  a {
    text-decoration: none !important;
  }

  &__links {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: url('../../../../assets/img/footer-bg.png') center top no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    justify-content: center;
    align-items: center;
    color: var(--hdb-white);
    padding-top: var(--hdb-space-9x);
    
    &--text-center {
      padding-top: 0;
      justify-content: center;
      align-items: center;

      .hdb-footer__top {
        justify-content: center;
        align-items: center;
        
        p, a {
          color: var(--hdb-white) !important;
        }
      }
    }
  }
  
  &__divider {
    display: block;
    height: 1px;
    width: 100%;
    background: var(--hdb-white);
  }
  
  &__top {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-top: var(--hdb-space-6x);
  }
  
  &__privacy-notice {
    margin-bottom: var(--hdb-space-6x);
  }

  &__logo {

    img {
      height: 60px;
    }
    
    & + .hdb-footer__menu {
      margin-left: var(--hdb-space-10x);
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    
    ul {
      
      li {
        
        a {
          color: var(--hdb-white) !important;
          font-size: 1.6rem;
          font-weight: 400;
        }
        
        & + li {
          margin-top: var(--hdb-space-4x);
        }
      }
    }
  }
  
  &__menu-grupo {

    strong {
      text-transform: uppercase;
      font-size: 1.6rem;

      & + ul {
        margin-top: var(--hdb-space-4x);
      }
    }
  }
  
  &__middle {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: var(--hdb-space-6x);
  }

  &__newsletter {
    align-self: flex-end;
    margin-bottom: var(--hdb-space-4x);
    
    p {
      font-size: 1.4rem;
    }
    
    .hdb-input {
      font-size: 1.6rem;
    }

    .hdb-input-group__append {
      font-size: 1.6rem;
      font-weight: 700;
    }
    
    .hdb-input::-webkit-input-placeholder,
    .hdb-input:-ms-input-placeholder,
    .hdb-input::placeholder {
      color: var(-hdb-purple-100);
    }
  }
  
  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--hdb-space-3x) 0 var(--hdb-space-6x) 0;
  }

  &__copyright {
    font-size: 1.6rem;
    font-weight: 700;
  }
  
  &__share-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;
    
    span {
      
      & + a {
        margin-left: var(--hdb-space-2x);
      }
    }
    
    a {
      
      & + a {
        margin-left: var(--hdb-space-2x);
      }
    }
  }

  &__partners {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: var(--hdb-space-3x);
    
    img {
      max-width: 90%;
    }

    p {
      margin-top: var(--hdb-space-3x);
      font-size: 1.2rem;
      font-weight: 500;
      text-align: center;
    }
  }

  &--center {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 575px) {
  .hdb-footer {

    &__top {
      flex-direction: column;
      align-items: center;
    }

    &__logo {

      & + .hdb-footer__menu {
        margin-left: 0 !important;
        margin-top: var(--hdb-space-10x);
      }
    }

    &__menu {
      flex-direction: column;
      align-items: center;
    }

    &__menu-grupo {
      width: 100% !important;
    }

    &__middle {
      margin-top: var(--hdb-space-10x);
    }

    &__newsletter {
      align-self: center;
    }

    &__bottom {
      flex-direction: column-reverse;
      align-items: center;
    }

    &__share-buttons {
      margin-bottom: var(--hdb-space-3x);
    }
  }
}

@media (max-width: 991px) {
  .hdb-footer {
    
    &__top {
      flex-direction: column;
      align-items: center;
    }

    &__logo {

      & + .hdb-footer__menu {
        margin-left: 0 !important;
        margin-top: var(--hdb-space-10x);
      }
    }
    
    &__menu {
      flex-direction: column;
      align-items: center;
    }
    
    &__menu-grupo {
      width: 50%;
      margin-bottom: var(--hdb-space-10x);
      text-align: center;
    }

    &__middle {
      margin-top: 0;
    }
    
    &__newsletter {
      align-self: center;
    }

    &__bottom {
      flex-direction: column-reverse;
      align-items: center;
    }
    
    &__share-buttons {
      margin-bottom: var(--hdb-space-3x);
    }
  }
}

@media (min-width: 992px) and (max-width: 1279px) {
  .hdb-footer {

    &__top {
      flex-direction: column;
    }
    
    &__logo {
      
      & + .hdb-footer__menu {
        margin-left: 0 !important;
        margin-top: var(--hdb-space-10x);
      }
    }
    
    &__middle {
      margin-top: var(--hdb-space-10x);
    }
  }
}
