.hdb-link {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: var(--hdb-blue) !important;
  font-size: 1.6rem;
  text-decoration: none !important;
  
  &--alternative {
    height: 80px;
    padding: var(--hdb-default-space) var(--hdb-space-3x);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--hdb-white);
    border: 1px solid var(--hdb-gray-20);
    border-radius: var(--hdb-half-space);
  }
  
  span + * {
    margin-left: var(--hdb-default-space);
  }
}

a {
  cursor: pointer;
  
  &.hdb-button {
    text-decoration: none !important;
  }
}
