.hdb-form {
  
  h1 {
    color: var(--hdb-blue);
    padding-bottom: var(--hdb-space-2x);
    font-weight: 700;
    font-size: 2rem;
    border-bottom: solid 1px var(--hdb-blue);
  }
  
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    a {
      text-decoration: none !important;
    }
    
    a, button {
      
      & * {
        margin-left: var(--hdb-space-2x);
      }
    }
  }
  
  &__divider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    span {
      padding: 0 12px;
      color: #8C8C8C;
    }
    
    &:before,
    &:after {
      content: '';
      height: 1px;
      background: #ccc;
      flex: 1;
      margin-top: var(--hdb-half-space);
    }
  }
  
  &__password-check {
    display: flex;
    flex-direction: row;

    i {
      font-size: 1.8rem;
      color: var(--hdb-red);

      & + span {
        margin-left: calc(var(--hdb-default-space) + var(--hdb-half-space));
      }

      &:before {
        content: "\e90d";
      }
    }
    
    &--success {
      
      i {
        color: var(--hdb-green) !important;
        
        &:before {
          content: "\e908" !important;
        }
      }
    }
  }
}
