.hdb-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
  -moz-box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
  box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
  background: var(--hdb-white);
  
  img {
    width: 100%;
    height: 224px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--hdb-space-3x) var(--hdb-space-4x) var(--hdb-default-space) var(--hdb-space-4x);
    border-right: solid 2px #f8f8f8;
    border-left: solid 2px #f8f8f8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  &__title {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 26px;
    display: block;
    display: -webkit-box;
    width: 100%;
    height: 24 * 26 * 3;
    margin: var(--hdb-space-2x) auto 0 0;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__description {
    display: block;
    display: -webkit-box;
    width: 100%;
    height: 16 * 26 * 2;
    margin: var(--hdb-space-2x) auto 0 0;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 26px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__actions {
    padding: var(--hdb-default-space) var(--hdb-default-space) var(--hdb-space-3x) var(--hdb-default-space);
  }
}

@media (max-width: 575px) {
  .hdb-card {
    
    &__body {
      padding: var(--hdb-space-2x) var(--hdb-space-2x) var(--hdb-default-space) var(--hdb-space-2x);
    }
  }
}
