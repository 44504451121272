.hdb-rich-text-markdown {
  margin-top: var(--hdb-space-4x);
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  p, ul, ol,
  li, pre, table,
  blockquote,
  h1, h2, h3,
  h4, h5, h6 {
    margin-top: var(--hdb-space-3x);
  }

  ul, ol {
    margin-left: 14px;
  }
}

@media (max-width: 991px) {
  .hdb-rich-text-markdown {
    line-height: 20px;

    p, ul, ol,
    li, pre, table,
    blockquote,
    h1, h2, h3,
    h4, h5, h6 {
      margin-top: var(--hdb-space-2x);
    }
  }
}