$base-spacing: 8;
$max-spacing: 50;

:root {
  --hdb-default-space: 8px;
  --hdb-half-space: calc(var(--hdb-default-space) / 2);
  
  @for $i from 1 through $max-spacing {
    --hdb-space-#{$i}x: calc(#{$i} * #{$base-spacing}px);
  }
}