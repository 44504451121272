.hdb-feature-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: var(--hdb-space-4x);
  
  &__top-image {
    width: 90%;
  }

  &__card {
    position: relative;
    width: 82%;
    margin: 0 auto;

    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      right: -10px;
      bottom: -10px;
      background: var(--hdb-blue);
      border-radius: 10px;
      z-index: 1;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
  
  &__logo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--hdb-blue);
    width: 400px;
    height: 200px; /* half of width*/
    border-radius: 200px 200px 0px 0px;
    top: -130px;
    z-index: 1;
    
    img {
      margin-top: -50px;
    }
  }

  &__title {
    position: relative;
    color: var(--hdb-blue);
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 30px;
    white-space: pre-wrap;
    background: var(--hdb-white);
    border-radius: 10px;
    border: solid 2px var(--hdb-blue);
    padding: var(--hdb-space-3x) var(--hdb-space-3x);
    text-align: center;
    width: 100%;
  }
}