.hdb-wizard {
  display: flex;
  flex-direction: column;
  
  &__progress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--hdb-space-6x) var(--hdb-space-10x);
    background: #F0F0F0;
    border-bottom: solid 1px var(--hdb-gray-20);
  }
  
  &__progress-indicator {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:after {
      background-color: var(--hdb-alternativa-blue) !important;
      content: '';
      display: block;
      height: 1px;
      right: calc(-50% - -20px);
      position: absolute;
      top: 20px;
      transition-delay: 0s;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: linear;
      width: calc(100% - 38px);
      z-index: 1;
    }

    &:last-child {

      &:after {
        display: none;
      }
    }
    
    &--active {
      
      &:hover {
        
        .hdb-wizard__progress-number {
          background: #0B326F;
        }
      }
      
      .hdb-wizard__progress-number {
        background: #0B326F;
        color: var(--hdb-white);
        border: 2px solid var(--hdb-white);
      }
      
      .hdb-wizard__progress-title {
        font-weight: 600;
        color: #0B326F;
      }
    }

    &--success {
      
      &:before {
        position: absolute;
        top: -10px;
        left: calc(50% + 4px);
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        content: '\f00c';
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-weight: 900;
        font-size: 1.1em;
        height: 28px;
        width: 28px;
        background: #168821;
        color: var(--hdb-white);
        border-radius: 100%;
      }
    }
  }

  &__progress-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: var(--hdb-white);
    color: var(--hdb-alternativa-blue);
    font-size: 2rem;
    font-weight: 700;
    border: 2px solid var(--hdb-alternativa-blue);
    width: var(--hdb-space-5x);
    height: var(--hdb-space-5x);
    border-radius: var(--hdb-space-5x);
    padding-bottom: 2px;
  }
  
  &_progress-title {
    margin-top: var(--hdb-space-2x);
    color: var(--hdb-alternativa-blue);
    font-size: 1.6rem;
  }
  
  &__step {
    display: none;
    flex-direction: column;
    
    &--active {
      display: flex;
    }
  }
  
  &__content {
    padding: var(--hdb-space-4x) 1.5%;
    
    .hdb-col {
      padding-top: var(--hdb-half-space);
      padding-bottom: var(--hdb-half-space);
    }
    
    &--review {
      
      .hdb-col {
        margin-top: var(--hdb-space-2x);
      }
      
      span {
        display: block;
        color: var(--hdb-blue);
        font-weight: 400;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--hdb-space-2x) 1.5%;
    background: #F0F0F0;
    border-top: solid 1px var(--hdb-gray-20);
    
    button {
      
      & + button {
        margin-left: var(--hdb-space-2x);
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .hdb-wizard {
    
    &__progress {
      padding: var(--hdb-space-4x) var(--hdb-space-2x);
    }
    
    &__progress-title {
      display: none !important;
    }
  }
}
