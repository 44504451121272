* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  
  &::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  &::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

html {
  font-size: 62.5%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--hdb-white);

  body {
    display: flex;
    flex-direction: column;
    color: var(--hdb-text-color);
    height: 100%;
    margin: 0;
    padding: 0;
    background: var(--hdb-white);
    font-family: var(--hdb-font-family);
    font-weight: 500;
    font-size: 1.4rem;
    overflow: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    letter-spacing: .03em;
    overflow-x: hidden;
    
    #root {
      position: relative;
      display: flex;
      flex-direction: column;
    }
    
    main {
      position: relative;
      flex: 1 0 auto;
      padding-top: var(--hdb-header-height);
      overflow-x: hidden;
    }

    a, button {
      cursor: pointer !important;
    }
  }
}

@media (max-width: 991px) {
  
  html {
    
    .container {
      padding: 0 var(--hdb-space-3x) !important;
    }
  }
}
