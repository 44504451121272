@import 'src/assets/styles/scss/abstracts/text';

.hdb-news-slider {
  --bs-gutter-x: 2.5rem;
  padding: var(--hdb-space-10x) 0;
  
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    .hdb-button {
      align-self: flex-end;
    }
  }
  
  &__title {
    @extend %hdb-title !optional;
    color: var(--hdb-blue) !important;
  }

  &__slider-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 503px;
    margin-top: var(--hdb-space-7x);
  }

  &__slider-items {
    position: relative;
    flex: 1;
    margin: 0;
    padding: 0 0 0 18px;
    height: 100%;
    overflow: hidden;
  }

  &__slider {
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    padding: 0;
    list-style: none;
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    left: 18px;
    transition: all 1s ease;
  }

  &__item {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: 0;
    display: inline-block;
    height: 491px;
    
    .hdb-rich-text-markdown {
      margin-top: 0 !important;
      
      p {
        margin-top: 0 !important;
      }
    }
  }

  &__previous,
  &__next {
    background: none !important;
    border: none !important;
    color: var(--hdb-white) !important;

    svg {
      font-size: 3rem;
      -webkit-filter: drop-shadow( 1px 1px 4px var(--hdb-black-alpha-25));
      filter: drop-shadow( 1px 1px 4px var(--hdb-black-alpha-25));
      color: var(--hdb-blue);
    }

    &:disabled,
    &[disabled]{
      opacity: 0.15;
      cursor: not-allowed !important;
    }
  }

  &__previous {
    left: 0;
  }

  &__next {
    right: 0;
  }

  .hdb-card {
    height: 100%;
    width: 386.328px;
  }
}

@media (max-width: 400px) {
  .hdb-news-slider {

    &__header {
      flex-direction: column;

      .hdb-button {
        margin-top: var(--hdb-space-4x);
      }
    }

    &__slider-wrapper {
      height: 468px;
    }

    &__previous,
    &__next {
      position: absolute;
      z-index: 2;
    }

    &__previous {
      left: -24px;
    }

    &__next {
      right: -24px;
    }
    
    &__slider-items {
      margin-left: -8px;
      z-index: 1;
    }

    &__item {
      height: 456px;
    }

    .hdb-card {
      height: 100%;
      width: 232px !important;

      img {
        width: 100%;
        height: 180px !important;
      }
    }
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .hdb-news-slider {

    &__header {
      flex-direction: column;

      .hdb-button {
        margin-top: var(--hdb-space-4x);
      }
    }

    &__slider-wrapper {
      height: 468px;
    }
    &__item {
      height: 456px;
    }

    .hdb-card {
      height: 100%;
      width: 300px !important;
      
      img {
        width: 100%;
        height: 180px !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .hdb-news-slider {
    
    &__header {
      flex-direction: column;
      align-items: flex-start;

      .hdb-button {
        margin-top: var(--hdb-space-4x);
      }
    }
    
    .hdb-card {
      height: 100%;
      width: 400px;
    }
  }
}