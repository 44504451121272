.hdb-select-group {
  position: relative;
  display: flex;
  flex-direction: row;

  .hdb-input {
    position: relative;
    flex: 1;
    
    &[disabled] + button {
      cursor: not-allowed;
      opacity: 0.45;
      background: #f4f4f4;
      
      &:hover {
        background-color: transparent;
      }
      
      &:active {
        background-color: transparent;
      }
    }
  }

  &:after {
    position: absolute;
    content: '\25BE';
    font-size: 1.6rem;
    top: 10px;
    right: var(--hdb-default-space);
    z-index: 2;
  }

  .hdb-button {
    
    &--circle {
      height: 32px !important;
      width: 32px !important;
      right: 4px;
      position: absolute;
      transform: translateY(4px);
      z-index: 1;

      i:first-child,
      svg:first-child {
        margin-right: 0 !important;
      }
    }
  }
}

.hdb-select {
  background-color: #fff;
  border: 1px solid #888;
  border-radius: var(--hdb-default-space);
  color: #333;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0 var(--hdb-space-2x);
  width: 100%;
  font-family: Arial, sans-serif;
  height: 40px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  z-index: 1;
}
