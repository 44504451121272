@for $i from 1 through $max-spacing {
  .pt-#{$i} {
    padding-top: var(--hdb-space-#{$i}x);
  }

  .pb-#{$i} {
    padding-bottom: var(--hdb-space-#{$i}x);
  }

  .pr-#{$i} {
    padding-right: var(--hdb-space-#{$i}x);
  }

  .pl-#{$i} {
    padding-left: var(--hdb-space-#{$i}x);
  }
}
