.hdb-news-carousel {
  position: relative;
  width: 100%;
  height: 548px;
  overflow: hidden;

  &__slider {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease;
    z-index: 1;
  }

  &__item {
    background: black;
    min-width: 100%;
    height: 548px;
    margin-right: 1rem;
    display: inline-block;
  }
  
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 var(--hdb-space-16x);

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    .hdb-button {
      position: absolute;
      justify-self: flex-end;
      bottom: 60px;
    }
  }

  &__info {
    padding: var(--hdb-space-4x);
    background: var(--hdb-gray-alpha);
    border-radius: var(--hdb-space-4x);
    width: 55%;
    align-self: flex-end;
    margin-top: 100px;
    -webkit-box-shadow: 0 4px 25px 0 var(--hdb-black-alpha-25);
    -moz-box-shadow: 0 4px 25px 0 var(--hdb-black-alpha-25);
    box-shadow: 0 4px 25px 0 var(--hdb-black-alpha-25);
  }

  &__title {
    font-size: 5rem;
    font-weight: 700;
    line-height: 55px;
    color: var(--hdb-white);
  }

  &__subtitle {
    font-size: 2rem;
    font-weight: 400;
    line-height: 36px;
    color: var(--hdb-white);
    margin-top: var(--hdb-space-2x);
  }

  &__previous,
  &__next {
    position: absolute;
    background: none !important;
    border: none !important;
    color: var(--hdb-white) !important;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    svg {
      font-size: 3rem;
      -webkit-filter: drop-shadow( 1px 1px 4px var(--hdb-black-alpha-25));
      filter: drop-shadow( 1px 1px 4px var(--hdb-black-alpha-25));
    }
  }

  &__previous {
    left: var(--hdb-space-4x);
  }

  &__next {
    right: var(--hdb-space-4x);
  }

  &__indicators {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
  }

  &__indicator,
  &__indicators li {
    width: 12px;
    height: 12px;
    background: var(--hdb-white);
    border-radius: 12px;
    -webkit-filter: drop-shadow( 1px 1px 4px var(--hdb-black-alpha-25));
    filter: drop-shadow( 1px 1px 4px var(--hdb-black-alpha-25));
    cursor: pointer;

    &:hover,
    &--active, {
      background: var(--hdb-blue) !important;
    }

    & + li {
      margin-left: var(--hdb-default-space);
    }
  }
}

@media (max-width: 575px) {
  .hdb-news-carousel {

    &__content {
      padding: 0 var(--hdb-space-2x) !important;
    }

    &__info {
      padding: var(--hdb-space-2x) !important;
    }

    &__title {
      font-size: 3rem !important;
      line-height: 35px;
    }
  }
}

@media (max-width: 991px) {
  .hdb-news-carousel {

    &__content {
      padding: 0 var(--hdb-space-4x);
    }

    &__info {
      width: 100%;
      max-width: 100%;
      padding: var(--hdb-space-3x);
      margin-top: var(--hdb-space-8x);
    }

    &__title {
      font-size: 4rem;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hdb-news-carousel {

    &__info {
      width: 80%;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .hdb-news-carousel {

    &__info {
      width: 70%;
    }
  }
}