.hdb-content-accordion {
  
  .hdb-accordion__title,
  .hdb-accordion__subtitle {
    font-size: 2rem;
    color: var(--hdb-blue);
  }
  
  .hdb-accordion__subtitle {
    font-weight: 400;
  }
  
  &__group-title {
    font-size: 1.7rem;
    line-height: 22px;
    padding: 0 var(--hdb-space-3x);
    margin-top: var(--hdb-space-2x);
    margin-bottom: var(--hdb-space-3x);
  }
  
  &__item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 22px;
    padding: 0 var(--hdb-space-3x);
    margin-top: var(--hdb-space-2x);
    
    svg {
      height: 8px;
      width: 8px;
      min-height: 8px;
      min-width: 8px;
      max-height: 8px;
      max-width: 8px;
      margin-top: 8px;
    }
    
    span {
      margin-left: var(--hdb-default-space);
    }
  }
}