@import 'src/assets/styles/scss/abstracts/text';

.hdb-learning-path {
  position: relative;
  color: var(--hdb-white);
  padding: var(--hdb-space-10x) 0 0 0;
  
  &__top {
    position: relative;
    display: flex;
    flex-direction: row;
    
    img {
      position: absolute;
      top: -80px;
      right: -180px;
      width: 50%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  
  &__title {
    @extend %hdb-title !optional;

    & + .hdb-learning-path__subtitle {
      margin-top: var(--hdb-space-4x);
    }
  }

  &__subtitle {
    color: var(--hdb-white);
    font-size: 3.2rem;
    font-weight: 700;

    & + .hdb-learning-path__description {
      margin-top: var(--hdb-space-4x);
    }
  }
  
  &__description {
    color: var(--hdb-white);
    font-size: 1.6rem;
    line-height: 26px;
  }
  
  &__paths {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--hdb-space-10x);
    padding-top: 200px;
    margin-left: 10%;
    margin-right: 10%;
  }

  &__path {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;

    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: calc(100% - 65px);
      right: -10px;
      bottom: -10px;
      background: var(--hdb-lilac);
      border-radius: 10px;
      z-index: 1;
    }

    &:nth-child(odd) {
      margin-top: -130px;
      align-self: flex-start;
    }
    
    &:nth-child(even) {
      margin-top: -130px;
      align-self: flex-end;
    }
  }
  
  &__more-path {
    max-width: 270px;
    text-align: center;
    margin-right: 90px;
    
    &:before {
      display: none;
    }
    
    p {
      font-size: 1.6rem;
      line-height: 18px;
      
      & + .hdb-button {
        margin-top: var(--hdb-space-3x);
      }
    }
  }

  &__path-number {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 110px;
    width: 110px;
    background: var(--hdb-white);
    border-radius: 110px;
    border: solid 2px var(--hdb-black);
    text-align: center;
    color: var(--hdb-green-90);
    font-size: 6.4rem;
    padding-bottom: 12px;
    z-index: 3;
  }
  
  &__path-text {
    position: relative;
    background: var(--hdb-white);
    border-radius: 10px;
    border: solid 2px var(--hdb-black);
    margin-top: -45px;
    color: var(--hdb-text-color);
    padding: var(--hdb-space-8x) var(--hdb-space-3x);
    z-index: 2;
    
    strong {
      color: var(--hdb-text-color);
      font-size: 2.4rem;
      
      & + p {
        margin-top: var(--hdb-space-2x);
      }
    }
    
    p {
      font-size: 1.6rem;
      line-height: 26px;
    }
  }
  
  &__register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 200px;

    .hdb-button {
      font-weight: 700;
    }
  }
  
  &__register-title {
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  &__register-text {
    font-size: 7rem;
    font-weight: 700;
    color: var(--hdb-green-90);
    
    & + .hdb-button {
      margin-top: var(--hdb-space-5x);
    }
  }
  
  &__application-process {
    margin-top: var(--hdb-space-20x);
  }
  
  &__ap-title {
    @extend %hdb-title !optional;

    & + .hdb-learning-path__ap-steps {
      margin-top: var(--hdb-space-8x);
    }
  }
  
  &__ap-steps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 var(--hdb-space-4x);
  }

  &__ap-step {
    max-width: 283px;
  }

  &__ap-step-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    & + .hdb-learning-path__ap-step-description {
      margin-top: var(--hdb-space-3x);
    }
  }
  
  &__ap-step-number {
    font-size: 6.4rem;
    font-weight: 700;
    
    & + .hdb-learning-path__ap-step-title {
      margin-left: var(--hdb-space-3x);
    }
  }
  
  &__ap-step-title {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: var(--hdb-default-space);
  }
  
  &__ap-step-description {
    font-size: 1.6rem;
    line-height: 26px;
    font-weight: 400;
  }
}

@media (max-width: 991px) {
  .hdb-learning-path {

    &__top {
      flex-direction: column;
      align-items: center;

      img {
        position: relative;
        top: 0;
        right: auto;
        max-width: 100%;
        margin-top: var(--hdb-space-10x);
      }
    }

    &__text {
      width: 100%;
    }

    &__paths {
      padding-top: 40px !important;
      margin-left: 0;
      margin-right: 0;
    }
    
    &__path {
      margin-top: 0 !important;
      margin-bottom: 100px !important;
      margin-right: 0 !important;
      align-self: center !important;
    }
    
    &__register {
      margin-top: var(--hdb-space-5x) !important;
    }

    &__register-text {
      font-size: 4.2rem;
    }
  }
}

@media (max-width: 1399px) {
  .hdb-learning-path {
    
    &__paths {
      padding-top: 200px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}