.hdb-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100em;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  height: 40px;
  padding: 0 var(--hdb-space-3x);
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  color: var(--hdb-white);
  background-color: var(--hdb-alternativa-blue);
  border: 1px solid #1351B4;
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.45;
  }
  
  &:hover {
    background-color: #3E6DC1;
    border: 1px solid #3E6DC1;
  }
  
  &:active {
    background-color: #829FD6;
    border: 1px solid #829FD6;
  }
  
  &--primary {
    color: var(--hdb-blue);
    background-color: var(--hdb-green-30);
    border: 1px solid var(--hdb-green-30);
    
    &:hover {
      background-color: var(--hdb-green-70);
      border: 1px solid var(--hdb-green-70);
    }
    
    &:active {
      background-color: var(--hdb-green-70);
      border: 1px solid var(--hdb-green-70);
    }
  }
  
  &--inverted {
    color: var(--hdb-blue);
    background-color: var(--hdb-white);
    border: 1px solid var(--hdb-green);
    
    &:hover {
      background-color: var(--hdb-green-30);
      border: 1px solid var(--hdb-green);
    }
    
    &:active {
      background-color: var(--hdb-green-70);
      border: 1px solid var(--hdb-green-70);
    }
  }
  
  &--transparent {
    color: var(--hdb-blue);
    background-color: transparent;
    border: 1px solid transparent;
    
    &:hover {
      background-color: var(--hdb-green-30);
      border: 1px solid var(--hdb-green-30);
    }
    
    &:active {
      background-color: var(--hdb-green-50);
      border: 1px solid var(--hdb-green-50);
    }
  }
  
  &--xl {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    height: var(--hdb-space-8x);
    padding: 0 var(--hdb-space-6x);
  }
  
  span + * {
    margin-left: var(--hdb-default-space);
  }
  
  &--alternative {
    border-radius: 6px;
  }
}

@media (max-width: 991px) {
  .hdb-button {

    &--xl {
      font-size: 1.8rem;
      padding: 0 var(--hdb-space-4x);
    }
  }
}
