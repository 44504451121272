.hdb-about-the-program {
  
  .hdb-title-subtitle-button {
    max-width: 67%;
    margin: 0 auto;
    padding-top: var(--hdb-space-2x);
  }

  &__opportunities {
    display: flex;
    flex-direction: column;
  }
  
  &__opportunities-title {
    color: var(--hdb-blue);
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: var(--hdb-space-10x);
    text-align: center;
  }
  
  &__video {
    position: relative;
    margin-top: var(--hdb-space-6x);
  }
}

@media (max-width: 575px) {
  .hdb-about-the-program {
    
    &__opportunities-title {
      font-size: 3.2rem;
    }
  }
}