@import 'src/assets/styles/scss/abstracts/text';

.hdb-faq {
  padding: var(--hdb-space-5x) 0 var(--hdb-space-10x) 0;
  
  &__title {
    @extend %hdb-title !optional;
    color: var(--hdb-blue) !important;

    & + .hdb-faq__list {
      margin-top: var(--hdb-space-5x);
    }
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .hdb-button {
      align-self: center;
    }
  }
  
  &__more {
    margin-top: var(--hdb-space-4x);
  }
}