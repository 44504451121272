.hdb-banner {
  display: flex;
  flex-direction: row;
  position: relative;
  
  .container {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: var(--hdb-space-15x) 0;
    z-index: 2;
  }
  
  &__text {
    width: 50%;
  }
  
  &__image {
    position: absolute;
    bottom: 0;
    right: -116px;
    max-width: 50%;
    max-height: 100%;
  }

  &--full {

    .container {
      min-height: 661px;
      align-items: center;
    }

    .hdb-banner__image {
      max-width: none;
      width: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
      right: auto;
      bottom: auto;
      z-index: 1;
    }
  }
}

@media (max-width: 991px) {
  .hdb-banner {
    display: flex;
    flex-direction: column;

    .container {
      flex-direction: column;
      justify-content: space-between;
    }

    &__text {
      width: 100%;
      margin-top: var(--hdb-space-15x);
    }

    &__image {
      position: relative;
      bottom: 0;
      right: auto;
      max-width: 100%;
      max-height: 100%;
    }
    
    &--full {

      .container {
        min-height: 661px;
        align-items: center;
      }

      .hdb-banner__image {
        position: absolute;
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
        right: auto;
        bottom: auto;
        z-index: 1;
      }

      .hdb-banner__text {
        width: 100%;
        margin-top: auto;
        margin-bottom: var(--hdb-space-15x);
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1279px) {
  .hdb-banner {

    &__text {
      width: 40%;
    }

    &__image {
      max-width: 60%;
    }
  }
}