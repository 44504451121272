@for $i from 1 through $max-spacing {
  .mt-#{$i} {
    margin-top: var(--hdb-space-#{$i}x);
  }

  .mb-#{$i} {
    margin-bottom: var(--hdb-space-#{$i}x);
  }

  .mr-#{$i} {
    margin-right: var(--hdb-space-#{$i}x);
  }

  .ml-#{$i} {
    margin-left: var(--hdb-space-#{$i}x);
  }
}