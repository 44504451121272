.hdb-accordion {
  background: var(--hdb-white);
  border: solid 2px #f8f8f8;
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
  -moz-box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
  box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &:checked {

      & ~ .hdb-accordion__content {
        padding: 0 0 var(--hdb-space-3x) 0;
        height: auto;
      }

      & ~ .hdb-accordion__collapse {

        .hdb-accordion__open-icon {
          display: none;
        }

        .hdb-accordion__close-icon {
          display: block;
        }
      }
    }
  }

  &__content {
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.35s;

    & > p {
      padding: 0 var(--hdb-space-3x);
      line-height: 26px;
    }
  }

  &__collapse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--hdb-text-color);
    padding: var(--hdb-space-3x);
    cursor: pointer;
  }
  
  &__title {
    flex: 1;
  }

  &__close-icon,
  &__open-icon {
    color: var(--hdb-blue);
    margin-left: var(--hdb-space-4x);
  }

  &__close-icon {
    display: none;
  }

  & + .hdb-accordion {
    margin-top: calc(var(--hdb-space-2x) + var(--hdb-half-space));
  }
}