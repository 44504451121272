.hdb-video-or-image {
  
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  &__video-background {
    width: 100%;
  }

  &__video-frame {
    position: absolute;
    top: 48%;
    left: 50%;
    width: 90%;
    height: 0;
    z-index: 2;
    transform: translateX(-50%) translateY(-50%);
    background: var(--hdb-black);

    video {
      width: 100%;
      height: 100%;
    }
  }
}