@import 'src/assets/styles/scss/abstracts/text';

.hdb-news-detail {
  position: relative;
  
  &__banner {
    position: relative;
    height: 611px;
    width: 100%;
    margin-bottom: var(--hdb-space-4x);
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    @extend %hdb-title !optional;
    color: var(--hdb-blue) !important;
  }
  
  &__content {
    position: relative;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    padding-top: var(--hdb-space-4x);
    padding-bottom: var(--hdb-space-8x);
  }
  
  .hdb-news-slider {
    margin-bottom: var(--hdb-space-14x);
  }
}