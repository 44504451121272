@import 'src/assets/styles/scss/abstracts/text';

.hdb-privacy-notice {
  
  &__title {
    @extend %hdb-title !optional;
    color: var(--hdb-blue) !important;
  }
  
  &__content {
    padding-bottom: var(--hdb-space-8x);
  }
  
  &__intro,
  &__section {
    margin-top: var(--hdb-space-4x);
  }
}