@import 'src/assets/styles/scss/abstracts/text';

.hdb-membership-levels {
  padding-top: var(--hdb-space-10x);

  &__title {
    color: var(--hdb-blue);
    font-size: 4rem;
    font-weight: 700;

    &:before {
      display: block;
      content: '';
      width: var(--hdb-space-10x);
      height: var(--hdb-space-2x);
      border-radius: var(--hdb-space-10x);
      background: var(--hdb-green-90);
      margin-bottom: var(--hdb-space-2x);
    }

    & + p {
      margin-top: var(--hdb-space-4x);
    }
  }
  
  p {
    font-size: 1.6rem;
  }
  
  &__tabs-container {
    margin-top: var(--hdb-space-8x);
    
    & > strong {
      font-size: 2.2rem;
    }
  }
  
  &__tabs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: var(--hdb-space-4x);

    [type=radio], [type=checkbox] {
      display: none;
      
      &:checked {
        
        & + label {
          flex: 1.3;
          color: var(--hdb-white);
          height: 120px;
          margin-top: -20px;
          font-size: 4rem;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          z-index: 10;

          &:nth-of-type(1){
            margin-left: 0;
          }
          
          & + .hdb-membership-levels__tab {
            display: block !important;
          }
        }
      }
    }
  }
  
  &__tab-label {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    cursor: pointer;
    color: rgba(255, 255, 255, .5);
    font-size: 3.5rem;
    font-weight: 700;
    transition: background-color ease 0.3s;
    height: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    margin-left: -10px;
    text-align: center;
    
    &:nth-of-type(1){
      margin-left: 0;
      border-top-left-radius: 10px;
      z-index: 9;
    }

    &:nth-of-type(2){
      z-index: 5;
    }
    
    &:nth-of-type(3){
      z-index: 4;
    }

    &:nth-of-type(4){
      z-index: 3;
    }

    &:nth-of-type(5){
      z-index: 2;
    }

    &:nth-of-type(6){
      z-index: 1;
    }
    
    &--lilac {
      background: rgb(27,20,100);
      background: linear-gradient(135deg, rgba(27,20,100,1) 0%, rgba(74,43,225,1) 78%);
    }
    
    &--orange {
      background: rgb(247,110,55);
      background: linear-gradient(135deg, rgba(247,110,55,1) 0%, rgba(254,133,45,1) 78%);
    }
    
    &--green {
      background: rgb(71,176,46);
      background: linear-gradient(135deg, rgba(71,176,46,1) 0%, rgba(1,214,1,1) 78%);
    }

    &--blue {
      background: rgb(30,64,175);
      background: linear-gradient(135deg, rgba(30,64,175,1) 0%, rgba(66,133,244,1) 78%);
    }

    &--magenta {
      background: rgb(112,26,117);
      background: linear-gradient(135deg, rgba(112,26,117,1) 0%, rgba(191,38,210,1) 78%);
    }
  }
  
  &__tab {
    position: relative;
    display: none;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    margin-top: -10px;
    z-index: 11;
    
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      right: -20px;
      bottom: -10px;
      border-radius: 10px;
      z-index: 1;
    }
    
    & > *:not(:last-child) {
      margin-bottom: 0.8rem;
    }

    &--lilac {
      
      &:before {
        background: var(--hdb-lilac);
      }

      .hdb-membership-levels__tab-content {
        border: solid 3px var(--hdb-lilac);
      }

      .hdb-membership-levels__tab-group-title {
        color: var(--hdb-lilac);
      }

      .hdb-membership-levels__tab-item {
        
        svg {
          color: var(--hdb-lilac);
        }
      }
    }

    &--orange {

      &:before {
        background: var(--hdb-orange);
      }

      .hdb-membership-levels__tab-content {
        border: solid 3px var(--hdb-orange);
      }

      .hdb-membership-levels__tab-group-title {
        color: var(--hdb-orange);
      }

      .hdb-membership-levels__tab-item {

        svg {
          color: var(--hdb-orange);
        }
      }
    }

    &--green {

      &:before {
        background: var(--hdb-green);
      }

      .hdb-membership-levels__tab-content {
        border: solid 3px var(--hdb-green);
      }

      .hdb-membership-levels__tab-group-title {
        color: var(--hdb-green);
      }

      .hdb-membership-levels__tab-item {

        svg {
          color: var(--hdb-green);
        }
      }
    }

    &--blue {

      &:before {
        background: var(--hdb-secundary-light-blue);
      }

      .hdb-membership-levels__tab-content {
        border: solid 3px var(--hdb-secundary-light-blue);
      }

      .hdb-membership-levels__tab-group-title {
        color: var(--hdb-secundary-light-blue);
      }

      .hdb-membership-levels__tab-item {

        svg {
          color: var(--hdb-secundary-light-blue);
        }
      }
    }

    &--magenta {

      &:before {
        background: var(--hdb-magenta);
      }

      .hdb-membership-levels__tab-content {
        border: solid 3px var(--hdb-magenta);
      }

      .hdb-membership-levels__tab-group-title {
        color: var(--hdb-magenta);
      }

      .hdb-membership-levels__tab-item {

        svg {
          color: var(--hdb-magenta);
        }
      }
    }
  }

  &__tab-content {
    position: relative;
    padding: 4rem;
    background-color: var(--hdb-white);
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: calc(100% + 10px);
    z-index: 2;
    
    hr {
      margin: var(--hdb-space-4x) 0;
      border-top: 1px solid var(--hdb-tertiary-gray);
    }
  }
  
  &__tab-group-title {
    font-size: 1.7rem;
    line-height: 22px;
    padding: 0;
    margin-bottom: var(--hdb-space-3x);
  }
  
  &__tab-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 22px;
    padding: 0;
    margin-top: var(--hdb-space-2x);

    svg {
      height: 8px;
      width: 8px;
      min-height: 8px;
      min-width: 8px;
      max-height: 8px;
      max-width: 8px;
      margin-top: 8px;
    }

    span {
      margin-left: var(--hdb-default-space);
    }
  }

  &__download {
    margin-top: var(--hdb-space-6x);
    text-align: center;
  }
}

@media (max-width: 767px) {

  .hdb-membership-levels {
    
    &__tabs {
      flex-direction: column;

      [type=radio], [type=checkbox] {
        display: none;

        &:checked {

          & + label {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:after {
              display: block;
              font-family: 'hdb-icons', sans-serif !important;
              content: "\e90c";
            }
          }
        }
      }
    }

    &__tab-label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: auto;
      margin-top: 10px !important;
      margin-left: 0;
      font-size: 2rem !important;
      border-radius: 10px;
      color: var(--hdb-white);
      padding: 3rem;
      box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);

      &:nth-of-type(1){
        margin-top: 0 !important;
      }
      
      &:after {
        display: block;
        font-family: 'hdb-icons', sans-serif !important;
        content: "\e909";
      }
    }

    &__tab {
      
      &:before {
        display: none !important;
      }
    }

    &__tab-content {
      margin-top: 10px !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 100%;
      border: none !important;
      box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
    }
  }
}

@media (min-width: 768px) {

  .hdb-membership-levels {
    
    &__tab-label {
      order: 1;
      width: auto;
    }

    &__tab {
      order: 9;
    }
  }
}
