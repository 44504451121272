.d-sm-none {
  display: block;
}

@media screen and (max-width: 991px) {
  
  .d-sm-none {
    display: none;
  }
}
