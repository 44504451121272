%hdb-title {
  color: var(--hdb-white);
  font-size: 4rem;
  font-weight: 700;

  &:before {
    display: block;
    content: '';
    width: var(--hdb-space-10x);
    height: var(--hdb-space-2x);
    border-radius: var(--hdb-space-10x);
    background: var(--hdb-green-90);
    margin-bottom: var(--hdb-space-2x);
  }
  
  @media (max-width: 575px) {
    font-size: 3.2rem;
  }
}