$color_1: var(--hdb-secundary-blue);
$color_2: var(--hdb-white);

.hdb-tooltip {
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  + {
    * {
      margin-left: 10px;
    }
  }
  &:hover {
    .hdb-tooltip__info {
      display: block;
    }
  }
}
.hdb-tooltip__icon {
  color: $color_1;
}
.hdb-tooltip__info {
  display: none;
  position: absolute;
  left: 50%;
  bottom: calc(100% + 18px);
  background: var(--hdb-secundary-blue);
  color: $color_2;
  padding: 12px;
  border-radius: 4px;
  transform: translateX(-50%);
  box-shadow: 9px 9px 6px 0px #00000029;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  width: 126px;
  max-width: 126px;
  &::after {
    position: absolute;
    display: block;
    content: '';
    left: 50%;
    bottom: -10px;
    margin: 0;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--hdb-secundary-blue);
    -webkit-filter: drop-shadow(9px 9px 6px #00000029);
    filter: drop-shadow(9px 9px 6px #00000029);
  }
  * {
    margin: 0;
  }
}
