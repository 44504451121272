@import 'src/assets/styles/scss/abstracts/text';

.hdb-levels-content {
  padding-top: var(--hdb-space-10x);

  &__title {
    @extend %hdb-title !optional;
    color: var(--hdb-blue) !important;
  }

  &__list {
    margin-top: var(--hdb-space-5x);
  }

  .hdb-image-and-topics {

    &:nth-child(1) {

      .hdb-image-and-topics__title,
      .hdb-image-and-topics__item svg {
        color: var(--hdb-lilac);
      }
    }

    &:nth-child(2) {

      .hdb-image-and-topics__title,
      .hdb-image-and-topics__item svg {
        color: var(--hdb-orange);
      }
    }

    &:nth-child(3) {

      .hdb-image-and-topics__title,
      .hdb-image-and-topics__item svg {
        color: var(--hdb-green);
      }
    }

    &:nth-child(4) {

      .hdb-image-and-topics__title,
      .hdb-image-and-topics__item svg {
        color: var(--hdb-secundary-blue);
      }
    }

    &:nth-child(5) {

      .hdb-image-and-topics__title,
      .hdb-image-and-topics__item svg {
        color: var(--hdb-blue);
      }
    }
  }
}