.hdb-blue-gradient-container {
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 492px;
    background: linear-gradient(180deg, #EBF5FF 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 523px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #EBF5FF 100%);
    z-index: 1;
  }

  * {
    z-index: 2;
  }
  
  & > .container {
    position: relative;
  }
}