@font-face {
  font-family: 'hdb-icons';
  src:  url('./hdb-icons.eot?ryzr00');
  src:  url('./hdb-icons.eot?ryzr00#iefix') format('embedded-opentype'),
  url('./hdb-icons.ttf?ryzr00') format('truetype'),
  url('./hdb-icons.woff?ryzr00') format('woff'),
  url('./hdb-icons.svg?ryzr00#hdb-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.hdb-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hdb-icons', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hdb-icon--caret-right:before {
  content: "\e900";
}
.hdb-icon--a-minus:before {
  content: "\e901";
}
.hdb-icon--a-plus:before {
  content: "\e902";
}
.hdb-icon--bars:before {
  content: "\e903";
}
.hdb-icon--caret-down:before {
  content: "\e904";
}
.hdb-icon--caret-left:before {
  content: "\e905";
}
.hdb-icon--caret-up:before {
  content: "\e906";
}
.hdb-icon--check-circle:before {
  content: "\e907";
}
.hdb-icon--check:before {
  content: "\e908";
}
.hdb-icon--chevron-down:before {
  content: "\e909";
}
.hdb-icon--chevron-left:before {
  content: "\e90a";
}
.hdb-icon--chevron-right:before {
  content: "\e90b";
}
.hdb-icon--chevron-up:before {
  content: "\e90c";
}
.hdb-icon--close:before {
  content: "\e90d";
}
.hdb-icon--contrast:before {
  content: "\e90e";
}
.hdb-icon--exclamation-circle:before {
  content: "\e90f";
}
.hdb-icon--eye-slash:before {
  content: "\e910";
}
.hdb-icon--eye:before {
  content: "\e911";
}
.hdb-icon--info-circle:before {
  content: "\e912";
}
.hdb-icon--lock:before {
  content: "\e913";
}
.hdb-icon--question-circle:before {
  content: "\e914";
}
.hdb-icon--search:before {
  content: "\e915";
}
.hdb-icon--times-circle:before {
  content: "\e916";
}
.hdb-icon--warning:before {
  content: "\e917";
}
