@import 'src/assets/styles/scss/abstracts/text';

.hdb-news-list {
  padding: var(--hdb-space-6x) 0;

  &__title {
    @extend %hdb-title !optional;
    color: var(--hdb-blue) !important;
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: var(--hdb-space-7x);
    padding: 0 var(--hdb-space-6x);
    
    & > .row {
      width: 100%;
    }
  }
  
  &__item {
    margin-bottom: var(--hdb-space-6x);
  }
  
  .hdb-card {
    height: 100%;
  }

  &__more {
    align-self: center;
    margin-top: var(--hdb-space-4x);
  }
}