@import 'src/assets/styles/scss/abstracts/text';

.hdb-course-content {
  
  &__title {
    @extend %hdb-title !optional;
    color: var(--hdb-blue) !important;
  }
  
  &__list {
    margin-top: var(--hdb-space-5x);
  }
  
  .hdb-content-accordion {
    
    &:nth-child(1) {

      .hdb-accordion__title,
      .hdb-accordion__subtitle,
      .hdb-content-accordion__group-title,
      .hdb-content-accordion__item svg {
        color: var(--hdb-lilac);
      }
    }

    &:nth-child(2) {

      .hdb-accordion__title,
      .hdb-accordion__subtitle,
      .hdb-content-accordion__group-title,
      .hdb-content-accordion__item svg {
        color: var(--hdb-orange);
      }
    }

    &:nth-child(3) {

      .hdb-accordion__title,
      .hdb-accordion__subtitle,
      .hdb-content-accordion__group-title,
      .hdb-content-accordion__item svg {
        color: var(--hdb-green);
      }
    }

    &:nth-child(4) {

      .hdb-accordion__title,
      .hdb-accordion__subtitle,
      .hdb-content-accordion__group-title,
      .hdb-content-accordion__item svg {
        color: var(--hdb-secundary-blue);
      }
    }

    &:nth-child(5) {

      .hdb-accordion__title,
      .hdb-accordion__subtitle,
      .hdb-content-accordion__group-title,
      .hdb-content-accordion__item svg {
        color: var(--hdb-blue);
      }
    }
    
    & + .hdb-content-accordion {
      margin-top: calc(var(--hdb-space-2x) + var(--hdb-half-space));
    }
  }
  
  &__download {
    margin-top: var(--hdb-space-6x);
    text-align: center;
  }
}