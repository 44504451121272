@import 'src/assets/styles/scss/abstracts/text';

.hdb-welcome {
  flex: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  
  &__background-container {
    position: relative;
    height: 100%;
    z-index: 2;
    
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background: url('../../../../assets/img/hdb-circles-bg-2.png') center top no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      z-index: 1;
    }
  }
  
  h1 {
    margin: 0;
    padding: 0;
    font-size: 4.2rem;
    margin-top: var(--hdb-space-10x);
    color: var(--hdb-blue);
  }

  p {
    color: var(--hdb-blue);
    font-size: 1.8rem;
    margin-top: var(--hdb-space-3x);
  }

  &__card-container {
    display: flex;
    justify-content: center;
    height: 100%;
    padding: var(--hdb-space-10x) 0;
  }
  
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 var(--hdb-space-3x);
    padding: var(--hdb-space-3x) var(--hdb-space-5x);
    background: var(--hdb-white);
    border: solid 6px transparent;
    border-radius: 10px;
    -webkit-box-shadow: 0 4px 4px 0 var(--hdb-black-alpha-25);
    -moz-box-shadow: 0 4px 4px 0 var(--hdb-black-alpha-25);
    box-shadow: 0 4px 4px 0 var(--hdb-black-alpha-25);
    cursor: pointer;
    color: var(--hdb-blue);
    
    &:hover {
      border: solid 6px var(--hdb-green);
    }
    
    strong {
      font-size: 1.8rem;
    }
    
    p {
      font-size: 1.4rem;
      color: var(--hdb-gray-70);
      width: 60%;
    }
    
    strong, p {
      margin-bottom: var(--hdb-space-3x);
    }
  }
}


@media screen and (max-width: 768px) {
  .hdb-welcome {

    &__card-container {
      flex-direction: column;
      width: 100%;
    }
    
    &__card {
      margin: var(--hdb-space-3x) 0;
    }
  }
}
