@import 'src/assets/styles/scss/abstracts/text';

.hdb-text-video-block {
  display: flex;
  flex-direction: row;
  padding: var(--hdb-space-10x) 0;
  align-items: center;

  &__left-video {

    & + .hdb-text-video-block__text {
      margin-left: var(--hdb-space-15x);
    }
  }

  &__left-video,
  &__right-video {
    position: relative;
  }
  
  &__right-video {
    max-width: 50%;
  }

  &__text {
    display: flex;
    flex-direction: column;
    flex: 1;

    &:before {
      display: block;
      content: '';
      width: var(--hdb-space-10x);
      height: var(--hdb-space-2x);
      border-radius: var(--hdb-space-10x);
      background: var(--hdb-green-90);
      margin-bottom: var(--hdb-space-2x);
    }

    & + .hdb-text-video-block__right-video {
      margin-left: var(--hdb-space-10x);
    }
  }

  &__subtitle {
    color: var(--hdb-blue);
    font-size: 2rem;

    & + .hdb-text-video-block__title {
      margin-top: var(--hdb-half-space);
    }
  }

  &__title {
    color: var(--hdb-blue);
    font-size: 4rem;
    font-weight: 700;

    & + .hdb-text-video-block__description,
    & + .hdb-text-video-block__checks {
      margin-top: var(--hdb-space-4x);
    }
  }

  &__description {
    color: var(--hdb-text-color);
    font-size: 1.6rem;
    line-height: 30px;
    white-space: pre-wrap;
  }

  &__more {
    font-weight: 700;
    margin-top: var(--hdb-space-4x);
  }
  
  &__checks {
    display: flex;
    flex-direction: column;
    padding-top: var(--hdb-half-space);
  }
  
  &__check-item {
    display: flex;
    flex-direction: row;
    
    svg {
      min-width: 14px;
      max-width: 14px;
      margin-top: calc(var(--hdb-default-space) + 2px);
    }
    
    span {
      margin-left: var(--hdb-space-2x);
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 30px;
    }
    
    & + .hdb-text-video-block__check-item {
      margin-top: var(--hdb-space-2x);
    }
  }
}

@media (max-width: 575px) {
  .hdb-text-video-block {

    &__title {
      font-size: 3.2rem;
    }
  }
}

@media (max-width: 1279px) {
  .hdb-text-video-block {
    flex-direction: column;
    padding: var(--hdb-space-6x) 0;

    &__text {
      margin-top: var(--hdb-space-8x);
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &__left-video,
    &__right-video {
      margin-top: var(--hdb-space-8x);
      margin-left: 0 !important;
      margin-right: 0 !important;
      max-width: 100% !important;
    }
  }
}
