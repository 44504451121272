@import 'src/assets/styles/scss/abstracts/text';

.hdb-partners {
  padding-top: var(--hdb-space-10x);
  
  &__title {
    @extend %hdb-title !optional;
    color: var(--hdb-blue) !important;

    & + .hdb-partners__list {
      margin-top: var(--hdb-space-6x);
    }
  }
  
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
    a {
      padding: var(--hdb-space-4x);

      img {
        max-width: 164px;
      }
    }
  }
}