:root {
  --hdb-font-family: Arial, sans-serif;
  
  --hdb-small-font-size: 1.2rem;
  --hdb-regular-font-size: 1.4rem;
  --hdb-medium-font-size: 1.6rem;
  --hdb-big-font-size: 2.1rem;
  --hdb-giant-font-size: 3.6rem;
  
  --hdb-header-height: 85px;
}
