.hdb-view-account {
  flex: 1;
  height: 100%;
  
  .hdb-blue-gradient-container {
    position: relative;
    height: 100%;

    &:after {
      display: none;
    }
  }

  &__circles {
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 2;
  }
  
  &__title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    &:after {
      display: block;
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 2px;
      background: var(--hdb-blue);
    }
  }
  
  &__data {
    padding-bottom: var(--hdb-space-6x);

    span, strong {
      display: block;
    }
    
    span {
      margin-top: var(--hdb-space-6x);
      color: var(--hdb-blue);
      
      & + strong {
        margin-top: var(--hdb-space-2x);
      }
    }
    
    strong {
      color: var(--hdb-text-color);
    }
  }
}

@media screen and (max-width: 500px) {
  .hdb-view-account {
    
    &__title {
      flex-direction: column;

      &:after {
        bottom: -18px;
      }
    }
    
    &__data {
      margin-top: 18px;
    }
  }
}
