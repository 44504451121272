.hdb-loader {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 999999;
  height: 100vh;
  width: 100%;
  color: var(--hdb-text-color);
  
  &--active {
    display: flex;
  }
  
  &--solid {
    background: rgba(255, 255, 255, 1);
  }
  
  &__spinner {
    width: 48px;
    height: 48px;
    border: 5px solid var(--hdb-blue);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  span {
    margin-top: var(--hdb-space-2x);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}
