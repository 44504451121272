@import 'src/assets/styles/scss/abstracts/text';

.hdb-hub-content {
  position: relative;
  background: linear-gradient(157deg, #140467 50%, #4A2BE1 100%);
  color: var(--hdb-white);
  padding: var(--hdb-space-10x) 0 0 0;
  z-index: 2;
  
  * {
    position: relative;
    z-index: 2;
  }
  
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('../../../../assets/img/background-conteudo-hub.png') center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 1;
  }

  &__title {
    @extend %hdb-title !optional;

    & + .hdb-hub-content__list {
      margin-top: var(--hdb-space-6x);
    }
  }
  
  &__list {
    padding-bottom: var(--hdb-space-20x);
  }
  
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--hdb-space-8x);
    
    img {
      width: 64px;
    }

    strong {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 26px;
      margin-top: var(--hdb-space-3x);
    }
    
    p {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 29px;
      margin-top: var(--hdb-space-2x);
    }
  }
}