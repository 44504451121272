.hdb-title-subtitle-button {
  display: flex;
  flex-direction: column;
  position: relative;

  &--center {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  &--alternative {
    
    h1 {
      color: var(--hdb-green) !important;
    }
  }
  
  .hdb-button {
    margin-top: var(--hdb-space-5x);
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: 4.2rem;
    color: var(--hdb-blue);
  }

  p {
    color: var(--hdb-blue);
    font-size: 1.8rem;
    margin-top: var(--hdb-space-5x);
  }
  
  &--light {
    
    h1 {
      color: var(--hdb-white);
    }

    p {
      color: var(--hdb-white);
    }
  }
  
  &--xl {

    h1 {
      font-size: 7rem;
    }
    
    p {
      font-size: 2.4rem;
      font-weight: 700;
    }
  }
}

@media (max-width: 991px) {
  .hdb-title-subtitle-button {

    h1 {
      font-size: 3.4rem;
    }

    p {
      font-size: 1.6rem;
    }

    &--xl {

      h1 {
        font-size: 4.2rem;
      }

      p {
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
  }
}