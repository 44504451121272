.hdb-image-and-topics {
  display: flex;
  flex-direction: row;
  padding: var(--hdb-space-6x) 0;
  align-items: center;

  &__left-image {

    & + .hdb-image-and-topics__text {
      margin-left: var(--hdb-space-10x);
    }
  }

  &__left-image,
  &__right-image {
    max-width: 50%;

    img {
      max-width: var(--hdb-space-48x);
    }
  }

  &--img-auto {

    .hdb-image-and-topics__left-image,
    .hdb-image-and-topics__right-image {
      max-width: 50%;

      img {
        max-width: 534px;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    flex: 1;

    & + .hdb-image-and-topics__right-image {
      margin-left: var(--hdb-space-10x);
    }
  }

  &__title {
    color: var(--hdb-blue);
    font-size: 2.9rem;
    font-weight: 700;
    line-height: 32px;

    & + .hdb-image-and-topics__description {
      margin-top: var(--hdb-space-4x);
    }
  }
  
  &__list {
    margin-top: var(--hdb-default-space);
  }
  
  &__item {
    display: flex;
    flex-direction: row;
    margin-top: var(--hdb-space-3x);
    
    svg {
      height: 16px;
      width: 16px;
      min-height: 16px;
      min-width: 16px;
      max-height: 16px;
      max-width: 16px;
      margin-top: 7px;
    }

    span {
      margin-left: var(--hdb-default-space);
      font-size: 1.7rem;
      line-height: 27px;
    }
  }
}

@media (max-width: 991px) {
  .hdb-image-and-topics {
    flex-direction: column;
    padding: var(--hdb-space-6x) 0;

    &__text {
      margin-top: var(--hdb-space-4x);
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    img {
      max-width: 100% !important;
    }

    &__right-image {
      margin-top: var(--hdb-space-4x);
      margin-left: 0 !important;
    }
    
    &__left-image,
    &__right-image {
      max-width: 100% !important;
    }

    &--img-auto {

      .hdb-image-and-topics__left-image,
      .hdb-image-and-topics__right-image {
        max-width: 50%;

        img {
          max-width: 534px;
        }
      }
    }
  }
}