.hdb-toast-container {
  position: absolute;
  top: var(--hdb-space-2x);
  right: var(--hdb-space-2x);
  display: flex;
  flex-direction: column;
  z-index: 9;
}

.hdb-toast {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0;
  max-width: 400px;
  border-radius: var(--hdb-half-space);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  
  & + .hdb-toast {
    margin-top: var(--hdb-default-space);
  }
  
  &__icon {
    display: flex;
    align-items: center;
    font-size: 2rem;
    padding-left: var(--hdb-space-2x);
    padding-right: var(--hdb-space-2x);
    
    & + .hdb-toast__content {
      padding-left: 0 !important;
    }
  }
  
  &__content {
    padding: var(--hdb-space-3x) var(--hdb-space-4x) var(--hdb-space-3x) var(--hdb-space-2x);
  }
  
  &__close {
    position: absolute;
    top: var(--hdb-half-space);
    right: var(--hdb-half-space);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    padding: var(--hdb-half-space);
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    background: transparent;
    color: var(--hdb-white);
    border: 0;

    i {
      font-size: 1.4rem;
    }
  }
  
  &--error {
    background: #fde0db;
    color: var(--hdb-text-color);

    i {
      color: #E52207;
    }
  }
  
  &--success {
    background: #e3f5e1;
    color: var(--hdb-text-color);

    i {
      color: #168821;
    }
  }
  
  &--warning {
    background: #fff5c2;
    color: var(--hdb-text-color);

    i {
      color: var(--hdb-text-color);
    }
  }
  
  &--info {
    background: #d4e5ff;
    color: var(--hdb-text-color);

    i {
      color: #155bcb;
    }
  }
}