.hdb-modal-alert{
  .hdb-modal__body {
    font-size: 1.4rem;

    p {
      width: 100%;
    }

    strong {
      color: var(--hdb-green);
    }
  }
}
