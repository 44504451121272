@import 'src/assets/styles/scss/abstracts/text';

.hdb-application-process {
  position: relative;
  color: var(--hdb-white);
  padding: var(--hdb-space-20x) 0;
  
  &__title {
    @extend %hdb-title !optional;

    & + .hdb-application-process__steps {
      margin-top: var(--hdb-space-8x);
    }
  }
  
  &__steps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 var(--hdb-space-4x);
  }

  &__step {
    max-width: 383px;
  }

  &__step-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    & + .hdb-application-process__step-description {
      margin-top: var(--hdb-space-3x);
    }
  }
  
  &__step-number {
    font-size: 6.4rem;
    font-weight: 700;
    
    & + .hdb-application-process__step-title {
      margin-left: var(--hdb-space-3x);
    }
  }
  
  &__step-title {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: var(--hdb-default-space);
  }
  
  &__step-description {
    font-size: 1.6rem;
    line-height: 26px;
    font-weight: 400;
  }
}

@media (max-width: 991px) {
  .hdb-application-process {
    padding: var(--hdb-space-18x) 0;

    &__steps {
      flex-direction: column;
    }

    &__step {
      max-width: 100%;
      
      & + .hdb-application-process__step {
        margin-top: var(--hdb-space-8x);
      }
    }
  }
}

@media (max-width: 1200px) {
  .hdb-application-process {

    &__step {
      margin-bottom: var(--hdb-space-8x);
    }
  }
}

@media (min-width: 992px) and (max-width: 1279px) {
  .hdb-application-process {

    &__step {
      margin-bottom: var(--hdb-space-8x);
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .hdb-application-process {

    &__step {
      margin-bottom: var(--hdb-space-8x);
      max-width: 483px;
    }
  }
}