.hdb-dropdown-menu {
  display: none;
  position: relative;
  cursor: pointer;
  
  &--show {
    display: block;
  }
  
  &--private-route {

    .hdb-dropdown-menu__page-menu {
      display: none !important;
    }
  }
  
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    strong {
      
      + i {
        margin-left: var(--hdb-default-space);
      }
    }
    
    i {
      font-size: 2rem;
    }
  }
  
  &__items {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;
    min-width: 200px;
    right: 0;
    top: 40px;
    max-height: calc(100vh - 100px);
    background: var(--hdb-white);
    -webkit-box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
    -moz-box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
    box-shadow: 0 6px 6px 0 var(--hdb-black-alpha-10);
    overflow: auto;
  }

  &__page-menu,
  &__item--register,
  &__item--signIn {
    display: none;
  }
  
  &__page-menu,
  &__user-menu {
    
    & > strong {
      display: block;
      width: 100%;
      padding: var(--hdb-space-2x) var(--hdb-space-3x);
      color: var(--hdb-gray-50);
      background: var(--hdb-gray-10);
      font-size: 1.2rem;

      & + .hdb-dropdown-menu__list {
        border-top: solid 2px var(--hdb-gray-20);
      }
    }
  }

  &__user-menu {
    border-top: solid 2px var(--hdb-gray-20);
  }
  
  &__list {
    list-style: none;
  }
  
  &__item {
    
    & + .hdb-dropdown-menu__item {
      border-top: solid 2px var(--hdb-gray-20);
    }
    
    &:hover {
      background: var(--hdb-blue);
      
      a, button {
        color: var(--hdb-white) !important;
      }

      i {
        
        &:before {
          color: var(--hdb-white) !important;
        }
      }
    }
    
    a, button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: var(--hdb-blue) !important;
      width: 100%;
      padding: var(--hdb-space-2x) var(--hdb-space-3x);

      span {
        display: inline-block;
        margin-right: var(--hdb-space-2x);
      }
    }
    
    button {
      background: transparent !important;
      border: none !important;
    }
  }
}

@media (max-width: 991px) {
  .hdb-dropdown-menu {
    display: block !important;

    &__page-menu,
    &__item--register,
    &__item--signIn {
      display: block;
    }
  }
}