.hdb-flex {
  display: flex;
  
  &--col {
    flex-direction: column;
  }
  
  &--row {
    flex-direction: row;
  }
  
  &--start {
    justify-content: flex-start;
  }
  
  &--end {
    justify-content: flex-end;
  }
  
  &--justify-center {
    justify-content: center;
  }
}
