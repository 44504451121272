.hdb-table {
  border-collapse: collapse;
  width: 100%;

  &--info {

    th {
      text-align: left;
      width: 200px;
      color: var(--hdb-blue);
      font-size: 1.4rem;
      font-weight: 700;
      background: #F0F0F0;
    }

    td, th {
      font-size: 1.4rem;
      padding: var(--hdb-space-2x) var(--hdb-space-3x);
      border-bottom: solid 1px #ccc;
    }

    th + td, td + td{
      border-left: solid 1px #ccc;
    }
  }
}
