.hdb-breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--hdb-space-4x) 0;
  min-height: 88px;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;

    a, span {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 16px;
    }

    a {
      color: var(--hdb-light-blue) !important;
      text-decoration: none !important;

      &:first-child {
        font-size: 1.6rem;
        color: var(--hdb-text-color) !important;
      }

      &:hover {
        text-decoration: underline !important;
      }

      & + a {

        &:before {
          display: block;
          content: '\f00c';
          font-family: 'Font Awesome 5 Free', sans-serif;
          font-weight: 900;
          font-size: 1.1em;
        }
      }
    }

    span {
      color: var(--hdb-text-color);
    }
  }
  
  &__home-link {
    margin-right: 10px;
  }
  
  &__chevron {
    color: var(--hdb-gray-20) !important;
    margin: 0 var(--hdb-half-space);
    
    svg {
      font-size: 10px;
      color: var(--hdb-gray-20) !important;
    }
  }
  
  &__back {
    font-weight: 500;
    
    svg + span {
      margin-left: var(--hdb-default-space);
    }
  }
}

@media (max-width: 500px) {
  .hdb-breadcrumb {
    flex-direction: column;
    
    .container {
      flex-direction: column;
    }
    
    &__back {
      margin-top: var(--hdb-space-2x);
    }
  }
}