.hdb-home {

  .hdb-banner {
    position: relative;
    background: linear-gradient(103.74deg, var(--dark-lilac) 37.83%, var(--hdb-lilac) 91.41%);
  }
  
  &__gradient {
    position: relative;
    background: linear-gradient(157deg, #140467 50%, #4A2BE1 100%);
    
    * {
      position: relative;
      z-index: 2;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 3251px;
      top: 0;
      left: 0;
      background: url('../../assets/img/background-trilha-de-apresendizado.png') center center no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      z-index: 1;
    }
  }
}