.hdb-modal-container {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background: var(--hdb-black-alpha-50);
  z-index: 99999;

  &--active {
    display: block;
  }
}

.hdb-modal {
  position: fixed;
  background: var(--hdb-white);
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  
  &--success {
    width: 365px !important;
    border-radius: 10px;
    padding: var(--hdb-space-3x);

    .hdb-modal__header {
      justify-content: center !important;
      align-items: center !important;
    }

    .hdb-modal__title {
      font-size: 24.19px !important;
      font-weight: 700 !important;
      word-wrap: break-word !important;
      text-align: center !important;
      width: 100% !important;
    }

    .hdb-modal__body {
      font-size: 1.4rem;
      
      p {
        width: 60%;
      }
      
      strong {
        color: var(--hdb-green);
      }
    }

    .hdb-modal__actions {
      justify-content: center;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--hdb-space-3x);
  }

  &__title {
    font-size: 20px;
    color: var(--hdb-blue);
  }

  &__close {
    background: transparent !important;
    border: none !important;
    color: var(--hdb-blue);
    font-size: 20px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--hdb-space-3x);
  }

  &__icon {
    margin-bottom: var(--hdb-space-3x);
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #686666;
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: var(--hdb-space-3x);

    .hdb-button + .hdb-button {
      margin-left: var(--hdb-space-2x);
    }
  }
}

@media (max-width: 500px) {
  .hdb-modal {
    max-width: 90%;
    
    &__actions {
      flex-direction: column;
      
      button {
        
        & + button {
          margin-left: 0 !important;
          margin-top: var(--hdb-space-2x);
        }
      }
    }
  }
}
