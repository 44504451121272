::-webkit-input-placeholder {
  text-transform: initial !important;
  color: var(--hdb-blue);
}

:-ms-input-placeholder {
  text-transform: initial !important;
  color: var(--hdb-blue);
}

::placeholder {
  text-transform: initial !important;
  color: var(--hdb-secondary-gray);
}

.hdb-input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: var(--hdb-default-space);
  margin-bottom: var(--hdb-space-2x);
  
  .hdb-list {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    max-height: 200px;
    min-width: 140px;
    overflow: auto;
    top: 98%;
    right: 0;
    width: 100%;
    z-index: 2;
  }
  
  .hdb-list--active {
    display: flex;
  }
  
  & + .hdb-text {
    margin-top: 0;
  }

  label + .hdb-input,
  label + .hdb-textarea,
  label + .hdb-select-group,
  label + .hdb-input-group__icon-group,
  label + .hdb-select,
  .hdb-radio-group {
    margin-top: var(--hdb-default-space);
  }
  
  &--required {
    & > label {
      &:after {
        display: block;
        content: '*';
        color: #e52207;
        font-weight: bold;
        font-family: monospace;
        padding-left: 2px;
      }
    }
  }
  
  &--small {
    width: 75px;
  }
  
  &--auto-complete {
    
    .hdb-list {
      height: 115px;
      overflow: scroll;
    }
    
    .hdb-list--small {
      height: 35px;
    }
    
    .hdb-list--small-2 {
      height: 80px;
    }
  }
  
  &__icon-group {
    position: relative;
    display: flex;
    flex-direction: row;
    
    &-border {
      border: 1px solid #888;
      border-radius: var(--hdb-default-space);
    }
  }
  
  &__prepend {
    color: var(--hdb-secondary-gray);
    left: calc(var(--hdb-half-space) + var(--hdb-default-space));
    z-index: 2;
    
    & + .hdb-input {
      padding: 0 var(--hdb-space-5x) 0 var(--hdb-space-5x);
    }
    
    &--alternative {
      color: #757575;
    }
  }
  
  &__append {
    color: var(--hdb-blue);
    right: calc(var(--hdb-half-space) + var(--hdb-default-space));
    cursor: pointer;
    z-index: 2;
    
    span {
      
      & + * {
        margin-left: var(--hdb-default-space);
      }
    }
  }

  &__prepend, &__append {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i, svg {
      font-size: 1.8rem;
    }
  }
}

.hdb-input {
  height: 40px;
  z-index: 1;
  
  &__code {
    height: 60px;
    text-align: center;
    font-size: 2.4rem;
    
    & + .hdb-input__code {
      margin-left: var(--hdb-default-space);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  &__radio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:hover {

        & + .hdb-input__radio-label {

          &:before {
            background: #dedede;
          }
        }
      }

      &:checked {

        & + .hdb-input__radio-label {

          &:before {
            background-color: #0B326F;
            box-shadow: inset 0 0 0 4px #ffffff;
          }
        }
      }

      &:focus {

        & + .hdb-input__radio-label {

          &:before {
            outline: none;
            border-color: #0B326F;
          }
        }
      }
      
      & + .hdb-input__radio-label {
        
        &:before {
          content: '';
          background: #ffffff;
          border-radius: 100%;
          border: 1px solid #898989;
          display: inline-block;
          width: var(--hdb-space-3x);
          height: var(--hdb-space-3x);
          position: relative;
          top: -0.2em;
          margin-right: var(--hdb-default-space);
          vertical-align: top;
          text-align: center;
          cursor: pointer;
          transition: all 250ms ease;
        }
        
        &:empty {
          
          &:before {
            margin-right: 0;
          }
        }
      }
    }

    & + .hdb-input__radio {
      margin-left: var(--hdb-space-3x);
    }
  }

  &__radio-label {
    cursor: pointer;
    font-weight: 500;
  }

  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      
      & ~ .hdb-input__checkmark {
        background-color: transparent;
        border: solid 1px #177B47;
        
        &:after {
          display: block;
        }
      }
    }
  }

  &__checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    background-color: transparent;
    margin: 0 !important;
    border-radius: var(--hdb-default-space);
    border: solid 1px var(--hdb-alternativa-blue);
    
    &:after {
      display: none;
      content: '\f00c';
      font-family: 'Font Awesome 5 Free', sans-serif;
      font-weight: 900;
      font-size: 1.1em;
      color: #177B47;
    }
  }
  
  &__feedback {
    display: none;
    font-size: 1.6rem;
    margin-top: var(--hdb-half-space);

    &--info,
    &--active {
      display: block;
      color: #1B1464;
    }

    &--error {
      color: #ff192c !important;

      small {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        background: #ff192c !important;
        color: #fff !important;
        padding: var(--hdb-half-space);

        i {
          font-size: 1.8rem;

          & + span {
            margin-left: var(--hdb-half-space);
          }
        }
      }
    }
  }
}

label {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--hdb-blue);
  font-size: 1.4rem;
  font-weight: 700;
  
  small {
    font-weight: 500;
    margin-left: var(--hdb-half-space);
  }
  
  & + .hdb-input-code-group {
    margin-top: var(--hdb-default-space);
  }
  
  & + .hdb-input__radio {
    margin-top: var(--hdb-default-space);
  }
}

.hdb-input,
.hdb-textarea,
.hdb-input__code {
  background-color: #fff;
  border: 1px solid #888;
  border-radius: var(--hdb-default-space);
  color: var(--hdb-blue);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0 var(--hdb-space-2x);
  width: 100%;
  font-family: Arial, sans-serif;
}

.hdb-input--uppercase,
.hdb-textarea--uppercase {
  text-transform: uppercase;
}

.hdb-input--no-border,
.hdb-textarea--no-border {
  border: none;
}
.hdb-input + .hdb-text,
.hdb-textarea + .hdb-text {
  margin-top: var(--hdb-default-space);
}

.hdb-input[disabled],
.hdb-textarea[disabled],
.hdb-input[readonly],
.hdb-textarea[readonly],
.hdb-input__radio input[type='radio']:disabled  + .hdb-input__radio-label,
.hdb-input__radio input[type='radio']:disabled + .hdb-input__radio-label:before {
  cursor: not-allowed !important;
  opacity: 0.45;
}

.hdb-input[disabled],
.hdb-input[readonly],
.hdb-textarea[disabled],
.hdb-input__radio input[type='radio']:disabled + .hdb-input__radio-label:before {
  background: #f4f4f4;
}

.hdb-input:hover,
.hdb-textarea:hover {
  background-color: #dedede;
}

.hdb-input:focus,
.hdb-textarea:focus,
.hdb-input:focus-visible,
.hdb-textarea:focus-visible {
  border-color: #c2850c;
  box-shadow: 0 0 0 2px #c2850c;
  outline: none;
}

.hdb-textarea {
  min-height: 160px;
  padding: 12px;
}

.hdb-upload {
  max-width: 550px;
  
  input {
    display: none;
  }
  
  label {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: transparent;
    color: var(--hdb-alternativa-blue);
    cursor: pointer;
    height: 40px;
    overflow: hidden;
    padding: 0 var(--hdb-space-3x);
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px dashed var(--hdb-alternativa-blue);
    border-radius: var(--hdb-default-space);
    font-size: 1.4rem;
    font-style: italic;
    font-weight: 400;
    margin-top: var(--hdb-default-space);
    max-width: 550px;
    min-height: 40px;
    padding-left: var(--hdb-space-2x);
    padding-right: var(--hdb-space-2x);
    text-align: left;
    width: 100%;
    
    svg {
      
      & + * {
        margin-left: var(--hdb-default-space);
      }
    }
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    margin-top: var(--hdb-default-space);
  }
  
  &__thumbnail {
    img {
      max-width: 300px;
      max-height: 300px;
    }
  }
  
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--hdb-default-space);
  }
  
  &__title {
    flex: 1;
    color: var(--hdb-alternativa-blue);
    
    & + * {
      margin-left: var(--hdb-space-2x);
    }
  }
  
  &__size {
    
    & + * {
      margin-left: var(--hdb-space-2x);
    }
  }
}

.hdb-file-list {
  display: flex;
  flex-direction: column;
  margin-top: var(--hdb-default-space);
  max-width: 550px;
  
  &__thumbnail {
    
    img {
      max-width: 300px;
      max-height: 300px;
    }
  }
  
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--hdb-default-space);
  }
  
  &__title {
    flex: 1;
    color: var(--hdb-alternativa-blue);
  }
  
  &__size {
    
    & + * {
      margin-left: var(--hdb-space-2x);
    }
  }
}

.hdb-input-code-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hdb-radio-group {
  display: flex;
  flex-direction: row;
}

.hdb-checkbox-group {
  position: relative;
  display: flex;
  flex-direction: row;
  
  label {
    color: var(--hdb-text-color);
    font-weight: 500;
    margin-left: var(--hdb-space-2x);
  }
}
