.hdb-text-image-block {
  display: flex;
  flex-direction: row;
  padding: var(--hdb-space-10x) 0;
  align-items: center;
  
  &__left-image {
    
    & + .hdb-text-image-block__text {
      margin-left: var(--hdb-space-10x);
    }
  }

  &__left-image,
  &__right-image {
    max-width: 50%;
    
    img {
      max-width: var(--hdb-space-48x);
    }
  }

  &--img-auto {

    .hdb-text-image-block__left-image,
    .hdb-text-image-block__right-image {
      max-width: 50%;

      img {
        max-width: 534px;
      }
    }
  }
  
  &__text {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    &:before {
      display: block;
      content: '';
      width: var(--hdb-space-10x);
      height: var(--hdb-space-2x);
      border-radius: var(--hdb-space-10x);
      background: var(--hdb-green-90);
      margin-bottom: var(--hdb-space-2x);
    }

    & + .hdb-text-image-block__right-image {
      margin-left: var(--hdb-space-10x);
    }
  }

  &__subtitle {
    color: var(--hdb-blue);
    font-size: 2rem;
    
    & + .hdb-text-image-block__title {
      margin-top: var(--hdb-half-space);
    }
  }

  &__title {
    color: var(--hdb-blue);
    font-size: 4rem;
    font-weight: 700;
    
    & + .hdb-text-image-block__description {
      margin-top: var(--hdb-space-4x);
    }
  }

  &__description {
    color: var(--hdb-text-color);
    font-size: 1.6rem;
    line-height: 30px;
    white-space: pre-wrap;
  }

  &__more {
    font-weight: 700;
    margin-top: var(--hdb-space-4x);
  }
}

@media (max-width: 575px) {
  .hdb-text-image-block {

    &__title {
      font-size: 3.2rem;
    }
  }
}

@media (max-width: 1279px) {
  .hdb-text-image-block {
    flex-direction: column;
    padding: var(--hdb-space-6x) 0;
    
    &__text {
      margin-top: var(--hdb-space-8x);
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    
    img {
      max-width: 100% !important;
    }
    
    &__right-image {
      margin-top: var(--hdb-space-8x);
      margin-left: 0 !important;
    }
  }
}