.hdb-icon-text-block {
  margin-top: var(--hdb-space-5x);
  
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    margin-bottom: var(--hdb-space-10x);
  }
  
  &__icon {
    width: var(--hdb-space-12x);
    
    & + .hdb-icon-text-block__title {
      margin-top: var(--hdb-space-2x);
    }
  }
  
  &__title {
    font-size: 2rem;
    line-height: 28px;
    max-width: 50%;
    text-align: center;

    & + .hdb-icon-text-block__description {
      margin-top: var(--hdb-space-2x);
    }
  }
  
  &__description {
    font-size: 1.6rem;
    line-height: 26px;
    max-width: 70%;
    text-align: center;
    color: var(--hdb-text-color);
  }
}

@media (max-width: 991px) {
  .hdb-icon-text-block {

    &__list {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      width: 100% !important;
    }
  }

}

@media (max-width: 1279px) {
  .hdb-icon-text-block {

    &__item {
      width: 50%;
    }
  }
}